import React, { useEffect, useState } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, } from "@material-ui/core";
import {
	AddCircle,
	Dashboard,
	ExpandLess,
	ExpandMore,
	GroupAdd,
	Inbox,
	List as ListIcon,
} from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";

interface Route {
	route: string;
	label: string;
	icon: React.ReactNode;
	children?: Route[];
}


const routes: Route[] = [
	{
		label: "Бази Данни",
		route: "/dashboarda",
		icon: <Dashboard />,
		children: [
			{
				label: "Списък",
				icon: <ListIcon />,
				route: RoutesUrl.dataBases.list,
			},
			{
				label: "Ново",
				icon: <AddCircle />,
				route: RoutesUrl.dataBases.create,
			},

		]
	},
	{
		label: "Маркетинг",
		route: "marketing",
		icon: <GroupAdd />,
		children: [
			{
				label: "Контрангенти",
				icon: "",
				route: "",
			},
			{
				label: "Списък",
				icon: <ListIcon />,
				route: RoutesUrl.contragents.list,
			},
			{
				label: "Нов",
				icon: <AddCircle />,
				route: RoutesUrl.contragents.create,
			},
			{
				label: "Изложения",
				icon: "",
				route: "",
			},
			{
				label: "Списък",
				icon: <ListIcon />,
				route: RoutesUrl.exhibitions.list,
			},
			{
				label: "Нов",
				icon: <AddCircle />,
				route: RoutesUrl.exhibitions.create,
			},
		]
	},
	{
		label: "Обслужване",
		route: "servicing",
		icon: <Dashboard />,
		children: [
			{
				label: "Списък",
				icon: <ListIcon />,
				route: RoutesUrl.servicing.list,
			},
			{
				label: "Ново",
				icon: <AddCircle />,
				route: RoutesUrl.servicing.create,
			},
		]
	},
	// {
	// 	label: "Бизнес Анализи",
	// 	icon: <ListIcon />,
	// 	route: RoutesUrl.businessAnalysis.list,
	// },
	{
		label: "Бизнес Анализ",
		route: RoutesUrl.businessAnalysis.list,
		icon: <Dashboard />,

	},
];


// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SideMenu() {
	const location = useLocation();
	const history = useHistory();


	const useStyles = makeStyles(() => ({
		icon: {
			minWidth: 30,
		},
		text: {
			whiteSpace: "nowrap",
		},
		root: {
			"&$disabled": {
				opacity: 1,
			},
		},
		disabled: {

		},
		child: {
			position: "relative",
			left: "6px",
			top: "-6px",
			fontSize: "20px",
		},
		nested: {
			"& span": {
				fontStyle: "italic",
			}
		},
		childNested: {
			paddingLeft: 25,
		}
	}));

	const classes = useStyles();

	const [open, setOpen] = useState("");

	const handleClick = (r: Route) => {
		const { route } = r;
		if (route === open) {
			setOpen("");
		} else {
			setOpen(r?.route || "");
		}
	}

	useEffect(() => {
		for (const route of routes) {
			if (route.children !== undefined) {
				const find = route.children.find((r) => `${r.route}` === location.pathname);

				if (find) {
					setOpen(find ? route.route : "");
					break;
				}

			}

			if (route.route === location.pathname) {
				setOpen(route.route);
				break;
			}
		}
	}, [location]);

	return (
		<List>
			{
				routes.map((r) => {
					if (r.children) {
						return (
							<div key={`${r.route}-container`}>
								<ListItem key={`${r.route}-dropdown-list-item`} button onClick={() => handleClick(r)} >
									<ListItemIcon style={{ minWidth: 30 }}>
										<Inbox />
									</ListItemIcon>
									<ListItemText primary={r.label} classes={{ root: classes.text }} />
									{open === r.route ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={open === r.route} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{
											r.children.map((rC) => {
												const link = `${rC.route}`;
												return (
													<ListItem button key={rC.route} className={rC.icon ? classes.childNested : classes.nested} selected={location.pathname === link} classes={{ root: classes.root, disabled: classes.disabled }} disabled={!rC.route} onClick={() => (rC.route ? (history.push(link)) : false)}>
														{rC.icon && <ListItemIcon classes={{ root: classes.icon }}>{rC.icon}</ListItemIcon>}
														<ListItemText primary={rC.label} />
													</ListItem>
												)
											})
										}
									</List>
								</Collapse>
							</div>
						)
					}

					return (
						<ListItem button key={`${r.route}-normal-list-item`} selected={location.pathname === r.route} onClick={() => (r.route ? (history.push(r.route)) : false)} disabled={r.route === undefined} classes={{ root: classes.root, disabled: classes.disabled }}>
							<ListItemIcon classes={{ root: classes.icon }}>{r.icon}</ListItemIcon>
							<ListItemText primary={r.label} classes={{ root: classes.text }} />
						</ListItem>
					)
				})
			}
		</List>
	);
}	

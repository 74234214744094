import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { CSVLink } from "react-csv";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import { useReactToPrint } from "react-to-print";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
// import { LabelKeyObject } from "react-csv/components/CommonPropTypes";

type Props = {
	csvHeaders: Array<any>;
	csvFileName: string;
	csvData: Array<any>
	componentRef: React.MutableRefObject<TableComponentToPrint | null>;
}

export function CardHeaderActions(props: Props) {
	const { csvHeaders: headers, componentRef, csvData, csvFileName } = props;

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<ButtonGroup variant="contained" color="secondary" >
			<Button>
				<CSVLink headers={headers} style={{ color: "inherit", height: "24px" }}
					data={csvData}
					filename={csvFileName}>
					<DescriptionIcon />
				</CSVLink>
			</Button>
			<Button onClick={() => handlePrint ? handlePrint() : null}>
				<PrintIcon />
			</Button>
		</ButtonGroup>
	)
}

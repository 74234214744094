import { createMuiTheme } from "@material-ui/core/styles";

const outerTheme = createMuiTheme({
	typography: {
		allVariants: {
			fontFamily: "Tahoma",
		}
	},
	palette: {
		background: {
			default: "#fff",
			paper: "#fffbeb"
		},
		primary: {
			main: "#206494"
		},
		secondary: {
			main: "#ffca0c",
		},
	},
	overrides: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		MuiButton: {
			sizeLarge: {
				borderRadius: "20px",
			},
			text: {
				textTransform: "none",
				textDecoration: "underline",
				fontSize: "16px",
				fontWeight: 400,
				color: "#238fbd",
			},
		},
		MuiCard: {
			root: {
				overflow: "initial"
			}
		}
	},
});

export default outerTheme;

import React from "react";
import {AppBar, Card, CardContent, makeStyles, Toolbar, Typography} from "@material-ui/core";
import Loader from "./Loader";


interface Props {
	title: string;
	headerActions?: any;
	children?: JSX.Element;
	isLoading?: boolean;
}


export default function CardComponent(props: Props) {
	const useStyles = makeStyles(() => ({
		container: {},
		title: {
			flexGrow: 1,
		},
		root: {
			minWidth: 250,
			position: "relative",
		},
	}));
	const classes = useStyles();
	return (
		<div>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						{props.title}
					</Typography>
					{
						props.headerActions && props.headerActions
					}
				</Toolbar>
			</AppBar>
			<Card className={classes.root}>
				<CardContent style={{padding: 16}}>
					{
						props.isLoading && (
							<Loader relative/>
						)
					}
					{
						props.children
					}
				</CardContent>
			</Card>
		</div>
	)
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableFooter } from "@material-ui/core";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHeadRoot: {
		fontWeight: 600,
	},
});

export interface Column {
	header: string;
	accessor?: string;
	cell: (cell: any) => JSX.Element;
}

interface Props {
	data: Array<any>;
	columns: Array<Column>;
	perPage?: number;
	rowElement?: (data: any, index: number) => JSX.Element
}

export default function TableComponent(props: Props) {
	const { columns, data, perPage = 5, rowElement = undefined } = props;
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};


	const headers = columns.map((column) => column.header);

	return (
		<TableContainer>
			<Table className={`${classes.table}`}>
				<TableHead>
					<TableRow>
						{
							headers.map((header, index) => <TableCell component="th" classes={{ root: classes.tableHeadRoot }} key={`${index}`} >{header}</TableCell>)
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
							if (rowElement !== undefined) {
								return rowElement(item, index);
							}

							return (
								<TableRow key={index}>
									{
										columns.map((column, columnIndex) => {
											const data = column.accessor ? item[column.accessor] : item;
											return (
												<TableCell component="td" scope="row" key={columnIndex}>
													{
														column.cell(data)
													}
												</TableCell>
											)
										})
									}
								</TableRow>
							)
						})
					}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
							colSpan={12}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={"Елементи на страница"}
							nextIconButtonText={"Следваща страница"}
							backIconButtonText={"Предишна страница"}
						/>
					</TableRow>
				</TableFooter>
			</Table>

		</TableContainer>
	);
}

import React from "react";
import {Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button} from "@material-ui/core";

type Props = {
	onClose: () => void;
	onSuccess: () => void;
	children: JSX.Element;
	show: boolean;
	title: string;
}

export default function AlertModal(props: Props) {
	const {show, children, onSuccess, onClose, title} = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<React.Fragment>
			<Dialog
				open={show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{children}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} >
						Затвори
					</Button>
					<Button onClick={onSuccess} autoFocus>
						ОК
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

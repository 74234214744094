import React, { useEffect, useState } from "react";
import {
    Box,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import CardComponent from "../shared/CardComponent";
import FormComponent, { FormData } from "../shared/test";
import { useHistory, useParams } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import { find, findAndUpdate, getData, store } from "../../services/service";

export default function Contragents() {
    let { id }: any = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataBases, setDatabases] = useState<any[]>([]);
    const [contragentNames, setContragentNames] = useState<string[]>([]);
    const [formData, setFormData] = useState<FormData>({ dynamicRows: [] as any, category: "" });
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            find("contragents", id).then((val) => {
                if (!!!val?.dynamicRows) {
                    val.dynamicRows = [];
                }
                setFormData(val);
            }).finally(() => setIsLoading(false));
        }
        getData("databases").then(((v) => {
            setDatabases(v);
        }))
        getData("contragents").then(((v) => {
            setContragentNames(v.map(c => c.name));
        }))
    }, [id])

    return (
        <Box>
            <CardComponent title={`Добавяне`} isLoading={isLoading}>
                <FormComponent
                    staticFields={[
                        {
                            name: 'name',
                            label: 'Име',
                            type: 'autocomplete',
                            required: true,
                            options: dataBases.map(el => el?.name || "").filter(el => !contragentNames?.includes(el)),
                            disabled: !!id ? true : false,
                            onChange: () => {
                                if (!!!id) {
                                    setFormData(((v) => {
                                        v.category = dataBases?.find(d => d?.name === v?.name)?.category;
                                        return v;
                                    }));
                                }

                            }
                        },
                        { name: 'category', label: 'Категория', type: 'text', disabled: true, required: true },
                        { name: 'sentPresentation', label: 'Изпратена фирмена презентация', type: "checkbox", required: false },
                        { name: 'receivedPresentation', label: 'Получена фирмена презентация', type: "checkbox", required: false },
                        { name: 'meeting', label: 'Проведена среща', type: "checkbox", required: false },

                    ]}
                    dynamicFields={[
                        { name: 'name', label: 'Наименование', size: 2, type: 'text', required: true, },
                        { name: 'sentOffer', label: 'Изпратено искане за оферта', size: 2, type: "checkbox", required: false },
                        { name: 'receivedOffer', label: 'Получена оферта', size: 2, type: "checkbox", required: false },
                        { name: 'contract', label: 'Договор', size: 1, type: "checkbox", required: false },
                        { name: 'orderDone', label: 'Изпълнена поръчка', size: 1, type: "checkbox", required: false },
                        { name: 'orderName', label: 'Наименование на поръчката', size: 2, type: "text", required: false, display: (row) => row.orderDone === true }
                    ]}
                    dynamicPlural="Наименования"
                    dynamicSingle="Наименование"
                    onSubmit={async (data) => {
                        try {
                            if (id) {
                                await findAndUpdate("contragents", id, data);
                                toast.success("Успешно редактиране");
                                push(RoutesUrl.contragents.list);
                                return;
                            }
                            await store("contragents", data);
                            toast.success("Успешно добавяне");
                            push(RoutesUrl.contragents.list);
                            return true;
                        } catch (e) {
                            toast.error("Нещо се обърка");
                            console.error(e);
                            return false;
                        }
                    }}
                    editMode={id ? true : false}
                    formData={formData}
                    setFormData={setFormData}
                />
            </CardComponent>
        </Box>
    );
}

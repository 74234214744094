import firebase from "../components/firebase";
import { Model } from "../models/base.interface";
import app from "firebase/app";
import { FormData } from "../components/shared/test";
import { base } from "../components/firebase";

export const store = (collectionName: string, data: FormData) => {
	return firebase.db.ref(base + "/" + collectionName).push(data);
}

export const setDbNote = async (id: string, note: string): Promise<void> => {
	try {
		const ref = firebase.db.ref(base + "/databases/" + id + "/notes");
		await ref.set(note);
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export const findAndUpdate = async (collectionName: string, id: string, data: FormData): Promise<void> => {
	try {
		const ref = firebase.db.ref(base + "/" + collectionName + "/" + id);
		await ref.update(data);
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export const find = async (collectionName: string, id: string): Promise<any> => {
	const snapshot = await firebase.db.ref(`${base}/${collectionName}/${id}`).once("value");
	return snapshot.val();
}

export const getData = async (collectionName: string): Promise<Array<any>> => {
	const snapshot = await firebase.db.ref(base + "/" + collectionName).once("value");
	const items: Array<any> = [];
	snapshot.forEach((childSnapshot: any) => {
		const data = { id: childSnapshot.key, ...childSnapshot.val() };
		items.push(data);
	});
	return items;
}

export const deleteItem = async (collectionName: string, id: string): Promise<void> => {
	try {
		const ref = firebase.db.ref(`${base}/${collectionName}/${id}`);
		await ref.remove();
	} catch (e) {
		console.error(e);
		throw e;
	}
}

import React from "react";
import { BarChart, Bar } from "recharts";
import { Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

interface Props {
	data: any[];
}

export default function App(props: Props) {
	return (
		<ResponsiveContainer width="95%" height={225}>
			<BarChart width={150} height={100} data={props.data} >
				<Bar dataKey="uv" fill="" />
				<CartesianGrid strokeDasharray="1" />
				<XAxis dataKey="name" angle={30} height={45} />
				<YAxis />
				<Line type="monotone" stroke="#8884d8" activeDot={{ r: 8 }} />
			</BarChart>
		</ResponsiveContainer>
	);
}

import { Person } from "../../components/DataBases/PersonToContactForm";
import { Model } from "../base.interface";


export enum DataBasesTypes {
    CLIENT = "Клиент",
    SUPPLIER = "Доставчик",
    PARTNER = "Партньор",
}

export const MapDataBasesTypes = {
    [DataBasesTypes.CLIENT]: "Клиент",
    [DataBasesTypes.SUPPLIER]: "Доставчик",
    [DataBasesTypes.PARTNER]: "Партньор"
}

export const dataBasesTypeOptions = Object.entries(MapDataBasesTypes).map(([key, value]) => {
    return {
        key,
        value,
    };
})

export interface DataBasesConstruct {
    id: string;
    name: string;
    type: DataBasesTypes;
    EIK: string;
    switchDdsRegistration: boolean;
    services: string;
    country: string;
    address: string;
    personsToContact: Person[];
    website: string;
    other: string;
}

export interface DBDataBasesConstruct extends DataBasesConstruct {
    id: string;
}

export class DataBases implements Model {
    public name: string;
    public type: DataBasesTypes;
    public EIK: string;
    public switchDdsRegistration: boolean;
    public services: string;
    public country: string;
    public address: string;
    public personsToContact: Person[];
    public website: string;
    public other: string;

    constructor(DataBases: DataBasesConstruct) {
        this.name = DataBases.name;
        this.type = DataBases.type;
        this.EIK = DataBases.EIK;
        this.switchDdsRegistration = DataBases.switchDdsRegistration;
        this.services = DataBases.services;
        this.country = DataBases.country;
        this.address = DataBases.address;
        this.personsToContact = DataBases.personsToContact;
        this.website = DataBases.website;
        this.other = DataBases.other;
    }

    dataToSave(): any {
        let finalString = "";
        if (this.personsToContact && typeof this.personsToContact !== 'string') {
            for (const person of this.personsToContact) {
                if (person) {

                    let str = "";
                    if (person?.name?.length > 0) {
                        str += "<b>Име:</b> " + person.name + "<br>";
                    }
                    if (person?.role?.length > 0) {
                        str += " <b>Длъжност:</b> " + person.role + "<br>";
                    }
                    if (person?.phone?.length > 0) {
                        str += " <b>Тел.:</b> " + person.phone + "<br>";
                    }
                    if (person?.email?.length > 0) {
                        str += " <b>Имейл:</b> " + person.email + "<br>";
                    }
                    if (str.length > 0) {
                        finalString += str + "<hr/>";
                    }
                }
            }
            finalString = finalString.slice(0, -5)
        } else {
            finalString = this.personsToContact
        }

        return {
            name: this.name,
            type: this.type,
            EIK: this.EIK,
            switchDdsRegistration: this.switchDdsRegistration,
            services: this.services,
            country: this.country,
            address: this.address,
            personsToContact: finalString,
            website: this.website,
            other: this.other,
        }
    }
}

export class DBDataBases extends DataBases {
    public id: string;
    constructor(DataBases: DBDataBasesConstruct) {
        super(DataBases);
        this.id = DataBases.id;
    }
}

export default DBDataBases;
import React, { useEffect, useRef, useState } from "react";
import TableComponent, { Column } from "../shared/TableComponent";
import CardComponent from "../shared/CardComponent";
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import DBDataBases, { MapDataBasesTypes, dataBasesTypeOptions, DataBasesTypes } from "../../models/DataBases/DataBases";
import { deleteItem, getData } from "../../services/service";
import AlertModal from "../shared/AlertModal";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import { getColumns, getDataBasesColumnsForExport } from "../../common/Utils/ActionUtils";
import { CardHeaderActions } from "../shared/CardHeaderActions";
const formatData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (key === "dynamicRows") {
                let i = 0;
                let finalString = "";
                for (const person of el[key]) {
                    if (person) {
                        let str = "";
                        if (person?.name?.length > 0) {
                            str += "Име: " + person.name;
                        }
                        if (person?.position?.length > 0) {
                            str += "<br/> Длъжност: " + person.position;
                        }
                        if (person?.phone?.length > 0) {
                            str += "<br/> Тел.: " + person.phone;
                        }
                        if (person?.email?.length > 0) {
                            str += "<br/> Имейл: " + person.email + "";
                        }
                        if (str.length > 0) {
                            i++;
                            finalString += i + "." + str + '<br/><br/>';
                        }
                    }
                }
                finalString = finalString.slice(0, -10)
                el[key] = finalString;
            }
            if (typeof el[key] === 'boolean') {
                if (el[key]) {
                    el[key] = "Да"
                } else {
                    el[key] = "Не"
                }
            }
        });
        return el;
    })
}
const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}
function List() {
    const [data, setData] = useState<Array<DBDataBases>>([]);
    const [selected, setSelected] = useState<DBDataBases | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openedModal, setOpenedModal] = useState<boolean>(false);
    const [currentSelected, setCurrentSelected] = useState<DataBasesTypes | string>("");
    const [currentCountry, setCurrentCountry] = useState<DataBasesTypes | string>("");
    const [currentSearch, setCurrentSearch] = useState<DataBasesTypes | string>("");

    const handleDelete = (dataBases: DBDataBases) => {
        setSelected(dataBases);
        setOpenedModal(true);
    }

    const handleModalSuccess = async () => {
        if (!selected || !selected.id) return;
        const { id } = selected;
        try {
            await deleteItem("databases", id);
            toast.success(`Успешно изтриване на ${selected.name}`);
            const newData = data.filter((proc) => proc.id !== id);
            setData(newData);
        } catch (e) {
            toast.error(`Неуспешно изтриване`);
        }

        setSelected(null);
        setOpenedModal(false);
    }

    const closeModal = () => {
        setSelected(null);
        setOpenedModal(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getData("databases").then((values) => {
            setData(formatData(values));
        }).finally(() => setIsLoading(false));
    }, [])

    const columns: Array<Column> = [
        {
            accessor: "name",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: "Име"
        },
        {
            accessor: "category",
            cell: (val: DataBasesTypes) => {
                return (
                    <Typography variant="body1">{MapDataBasesTypes[val]}</Typography>
                )
            },
            header: "Категория"
        },
        {
            accessor: "eik",
            cell: (val) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "ЕИК"
        },
        {
            accessor: "dds",
            cell: (val: string | undefined) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                );
            },
            header: "Регистрация по ДДС"
        },
        {
            accessor: "services",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "Услуги"
        },
        {
            accessor: "country",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "Държава"
        },
        {
            accessor: "address",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "Адрес"
        },
        {
            accessor: "dynamicRows",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={'test'}>
                        <span dangerouslySetInnerHTML={{ __html: val }}></span>
                    </Typography>
                )
            },
            header: "Лицa за контакт"
        },

        {
            accessor: "site",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "Сайт"
        },
        {
            accessor: "others",
            cell: (val: string) => {
                return (
                    <Typography variant="body1" title={val}>{val}</Typography>
                )
            },
            header: "Други"
        },
        {
            cell: (dataBases) => {
                return (
                    <Box display="flex">
                        <Link to={`${RoutesUrl.dataBases.edit(dataBases.id)}`}>
                            <Typography color="primary" title="Редакция"><EditIcon /></Typography>
                        </Link>
                        <Typography color="primary" title="Изтриване" onClick={() => handleDelete(dataBases)} style={{ cursor: "pointer" }}>
                            <DeleteIcon />
                        </Typography>
                    </Box>
                )
            },
            header: "Действие"
        },
    ];

    const handleChange = (type: DataBasesTypes | string) => {
        setCurrentSelected(type);
    };

    let componentRef = useRef<TableComponentToPrint | null>(null);

    const csvColumns = getDataBasesColumnsForExport();

    const renderHeaderActions = () => {
        if (!data.length) return;
        const csvData = getCsvData(data);
        const csvFileName = `DataBases_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    const filterData = (data: any[]) => {
        return data.filter((el) => {
            if (!el?.country) {
                el.country = "";
            }
            let searchConcat = (el?.name || "") + " " + (el?.country || "") + " " + (el?.dynamicRows || "") + " " + (el?.other || "");
            return el.category.includes(currentSelected || "") && el.country.toLowerCase().includes(currentCountry.toLowerCase() || "")

                && searchConcat.toLowerCase().includes(currentSearch.toLowerCase() || "");
        })
    }
    console.log("fl", filterData(data).length)
    return (
        <CardComponent title={`Списък Бази Данни`} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <AlertModal onClose={closeModal} onSuccess={handleModalSuccess} show={openedModal} title={'Изтриване'} >
                    <Typography>Сигурни ли сте, че искате да изтриете {selected ? selected.name : ""} ?</Typography>
                </AlertModal>
                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel>Категория</InputLabel>
                            <Select
                                fullWidth
                                onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                    handleChange(e.target.value as string || "")
                                }}
                                label={"Категория"}
                            >
                                <MenuItem value={""} key={-1}>Моля, изберете</MenuItem>
                                {
                                    dataBasesTypeOptions.map((item) => (
                                        <MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentCountry(e.target.value as string);
                                }}
                                label={"Държава"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentSearch(e.target.value as string);
                                }}
                                label={"Търсене..."}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    data.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={data}
                                headers={csvColumns}
                                title={"Списък Бази Данни"}
                            />
                        </div>
                    )
                }
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                <TableComponent data={filterData(data)} columns={columns} />
            </Box>
        </CardComponent>
    )
}


export default List;

import React, { useState, useEffect, useCallback, ChangeEvent, FormEvent } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Divider,
    GridSize,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface FieldConfig {
    name: string;
    label: string;
    type: 'text' | 'number' | 'checkbox' | 'autocomplete' | 'date';
    required: boolean;
    options?: string[]; // For autocomplete
    size?: GridSize;
    validation?: (value: any) => string | undefined;
    display?: (formData: FormData | DynamicRow) => boolean; // Function to determine if the field should be displayed
    disabled?: boolean;
    value?: string;
    onChange?: () => void;
}

export interface DynamicRow {
    id: string;
    [key: string]: string | number | boolean;
}

export type FormData = {
    [K in FieldConfig['name']]?: string | number | boolean;
} & { dynamicRows: DynamicRow[] };

type FormErrors = {
    [K in FieldConfig['name']]?: string;
} & { dynamicRows: { [key: string]: string }[] };

interface MyFormProps {
    staticFields: FieldConfig[];
    dynamicFields?: FieldConfig[];
    onSubmit?: (data: FormData) => void;
    dynamicPlural?: string;
    dynamicSingle?: string;
    editMode?: boolean;
    dataChange?: (value: FormData) => FormData;
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>
}

const MyForm: React.FC<MyFormProps> = ({
    staticFields,
    dynamicFields,
    onSubmit,
    dynamicPlural,
    dynamicSingle,
    editMode,
    setFormData,
    formData
}) => {

    const generateInitialFormErrors = useCallback((): FormErrors => {
        const initialFormErrors: FormErrors = { dynamicRows: [] }; // Initialize with an empty array
        staticFields.forEach((field) => {
            initialFormErrors[field.name] = '';
        });
        return initialFormErrors;
    }, [staticFields]);

    // const [formData, setFormData] = useState<FormData>(
    //     initialValues || generateInitialFormData()
    // );
    const [formErrors, setFormErrors] = useState<FormErrors>(
        generateInitialFormErrors()
    );

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any,
        newValue?: string | null
    ): void => {
        const name = event.target.name || event.target.getAttribute('name');
        const value = event.target.value;
        const type = event.target.type;
        const checked = event.target.checked;

        switch (type) {
            case 'checkbox':
                setFormData({
                    ...formData,
                    [name]: newValue !== undefined ? newValue : checked,
                });
                break;
            case 'number':
                setFormData({
                    ...formData,
                    [name]: newValue !== undefined ? newValue : parseInt(value),
                });
                break;
            case 'date':
                setFormData({
                    ...formData,
                    [name]: newValue !== undefined ? newValue : value,
                });
                break;
            case 'text':
                setFormData({
                    ...formData,
                    [name]: newValue !== undefined ? newValue : value,
                });
                break;
            default:
                setFormData({
                    ...formData,
                    [name]: newValue !== undefined ? newValue : value,
                });
        }
    };


    const handleDynamicRowChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any,
        id: string,
        newValue?: string | null
    ): void => {
        const name = event.target.name || event.target.getAttribute('name');
        const value = event.target.value;
        const type = event.target.type;
        const updatedDynamicRows = formData.dynamicRows.map((row) =>
            row.id === id ? { ...row, [name]: newValue !== undefined ? newValue : (type === 'checkbox' ? !row[name] : value) } : row
        );
        setFormData({ ...formData, dynamicRows: updatedDynamicRows });
    };


    const addRow = (): void => {
        if (dynamicFields && formData.dynamicRows) {
            const newRow = { id: `${Date.now()}` };
            dynamicFields?.forEach((field) => {
                newRow[field.name] = '';
            });

            // Check if there are no dynamic rows yet, then add the initial row
            if (formData.dynamicRows.length === 0) {
                setFormData({ ...formData, dynamicRows: [newRow] });
            } else {
                setFormData({ ...formData, dynamicRows: [...formData.dynamicRows, newRow] });
            }
        }
    };

    const deleteRow = (id: string): void => {
        const updatedDynamicRows = formData.dynamicRows.filter((row) => row.id !== id);
        setFormData({ ...formData, dynamicRows: updatedDynamicRows });
    };

    const validate = (): boolean => {
        let isValid = true;
        const newFormErrors: FormErrors = { dynamicRows: [] };

        // Validate static fields
        staticFields.forEach((field) => {
            const value = formData[field.name];
            const errorMessage = field.validation ? field?.validation(value as string | number | boolean) : false;
            if (errorMessage) {
                newFormErrors[field.name] = errorMessage;
                isValid = false;
            }
        });

        // Validate dynamic rows
        formData.dynamicRows.forEach((row) => {
            const rowErrors: { [key: string]: string } = { id: row.id };
            dynamicFields?.forEach((field) => {
                const value = row[field.name];
                const errorMessage = field.validation ? field?.validation(value as string | number | boolean) : false;
                if (errorMessage) {
                    rowErrors[field.name] = errorMessage;
                    isValid = false;
                }
            });
            newFormErrors.dynamicRows.push(rowErrors);
        });

        setFormErrors(newFormErrors);
        return isValid;
    };

    const resetForm = useCallback((): void => {
        setFormData({ dynamicRows: [] as any });
        setFormErrors(generateInitialFormErrors());
    }, [generateInitialFormErrors, setFormData]);

    useEffect(() => {
        // if (initialValues) {
        // setFormData(initialValues);
        // setFormErrors(generateInitialFormErrors());
        // } else {
        // setFormData(generateInitialFormData());
        // setFormErrors(generateInitialFormErrors());
        // }
    }, [generateInitialFormErrors, setFormData]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (validate()) {
            if (onSubmit) {
                onSubmit(formData);
            }
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
                {staticFields.map((field) => {
                    return field.display ? field.display(formData) : true && <Grid item xs={field.size || 12} key={field.name}>
                        {field.type === 'checkbox' ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={field.name}
                                        checked={formData?.[field.name] === true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const newd = {
                                                ...formData,
                                                [field.name]: formData?.[field.name] !== true
                                            };
                                            setFormData(newd);
                                        }}
                                    />
                                }
                                label={field.label}
                            />
                        ) : field.type === 'autocomplete' && field.options ? (
                            <Autocomplete
                                disablePortal
                                key={field.name}
                                id={field.name}
                                autoHighlight
                                autoSelect
                                autoComplete
                                disableClearable
                                options={field.options}
                                value={formData[field.name] as string | undefined || ""}
                                onInputChange={(event, newValue) => {
                                    // const newValue = event?.currentTarget?.value;
                                    if (newValue) {
                                        const newd = {
                                            ...formData,
                                            [field.name]: newValue
                                        };
                                        setFormData(newd);
                                        if (field.onChange) {
                                            field.onChange();
                                        }
                                    }
                                }}
                                getOptionLabel={(option) => {
                                    return option;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name={field.name}
                                        label={field.label}
                                        required={field.required}
                                        fullWidth
                                        margin="normal"
                                        error={Boolean(formErrors[field.name])}
                                        helperText={formErrors[field.name]}
                                    />
                                )}
                                disabled={field.disabled}
                            />
                        ) : field.type === 'date' ? (
                            <TextField
                                name={field.name}
                                label={field.label}
                                type="date"
                                required={field.required}
                                value={formData[field.name] || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(formErrors[field.name])}
                                helperText={formErrors[field.name]}
                                disabled={field.disabled}
                            />
                        ) : (
                            <TextField
                                id={field.name}
                                key={field.name}
                                name={field.name}
                                label={field.label}
                                type={field.type}
                                required={field.required}
                                value={formData?.[field.name] || field?.value || ""}
                                defaultValue={formData[field.name]}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(formErrors[field.name])}
                                helperText={formErrors[field.name]}
                                disabled={field?.disabled}
                                InputLabelProps={{ shrink: !!(formData?.[field.name] || field?.value || "") }}
                            />
                        )}
                    </Grid>
                })}
                {dynamicFields && <>
                    {dynamicPlural && <Grid item xs={12}><Typography>{dynamicPlural}</Typography></Grid>}
                    {formData.dynamicRows.map((row, index) => (
                        <React.Fragment key={row.id}>
                            <Grid item xs={1}>
                                <Typography>{index + 1}.</Typography>
                            </Grid>
                            {dynamicFields.map((field) => {

                                return <Grid item xs={field.size || 3} key={`${field.name}_${row.id}`}>
                                    <>
                                        {
                                            field.type === 'checkbox' ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={field.name}
                                                            checked={Boolean(row[field.name])}
                                                            onChange={(e) => handleDynamicRowChange(e, row.id)}
                                                        />
                                                    }
                                                    label={field.label}
                                                />
                                            ) :
                                                field.type === 'autocomplete' && field.options ? (
                                                    <Autocomplete
                                                        disablePortal
                                                        key={field.name}
                                                        id={field.name}
                                                        autoHighlight
                                                        autoSelect
                                                        autoComplete
                                                        disableClearable
                                                        options={field.options}
                                                        value={formData[field.name] as string | undefined || ""}
                                                        onInputChange={(event, newValue) => {
                                                            // const newValue = event?.currentTarget?.value;
                                                            if (newValue) {

                                                                const updatedDynamicRows = formData.dynamicRows.map((r) =>
                                                                    r.id === row.id ? { ...r, [field.name]: newValue } : r
                                                                );
                                                                setFormData({ ...formData, dynamicRows: updatedDynamicRows });
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => {
                                                            return option;
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name={field.name}
                                                                label={field.label}
                                                                required={field.required}
                                                                fullWidth
                                                                margin="normal"
                                                                error={Boolean(formErrors[field.name])}
                                                                helperText={formErrors[field.name]}
                                                            />
                                                        )}
                                                        disabled={field.disabled}
                                                    />
                                                ) : field.type === 'date' ? (
                                                    <TextField
                                                        name={field.name}
                                                        label={field.label}
                                                        type="date"
                                                        required={field.required}
                                                        value={row[field.name]}
                                                        onChange={(e) => handleDynamicRowChange(e, row.id)}
                                                        fullWidth
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(
                                                            formErrors.dynamicRows[index] &&
                                                            formErrors.dynamicRows[index][field.name]
                                                        )}
                                                        helperText={
                                                            formErrors.dynamicRows[index] &&
                                                            formErrors.dynamicRows[index][field.name]
                                                        }
                                                    />
                                                ) : (
                                                    <TextField
                                                        name={field.name}
                                                        label={field.label}
                                                        type={field.type}
                                                        required={field.display ? field.display(row) : field.required}
                                                        disabled={field.display ? !field.display(row) : false}
                                                        value={row[field.name]}
                                                        onChange={(e) => handleDynamicRowChange(e, row.id)}
                                                        fullWidth
                                                        margin="normal"
                                                        error={Boolean(
                                                            formErrors.dynamicRows[index] &&
                                                            formErrors.dynamicRows[index][field.name]
                                                        )}
                                                        helperText={
                                                            formErrors.dynamicRows[index] &&
                                                            formErrors.dynamicRows[index][field.name]
                                                        }
                                                    />
                                                )
                                        }
                                    </>
                                </Grid>
                            })}
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={() => deleteRow(row.id)}
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                        <Button
                            type="button"
                            onClick={addRow}
                            variant="contained"
                            color="primary"
                        >
                            Добави {dynamicSingle}
                        </Button>
                    </Grid>
                </>
                }
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={editMode ? 12 : 6}>
                    <Button fullWidth type="submit" variant="contained" color="primary">
                        {editMode ? "Редактирай" : "Добави"}
                    </Button>
                </Grid>
                {
                    !editMode && <Grid item xs={6}>
                        <Button
                            fullWidth
                            type="button"
                            onClick={resetForm}
                            variant="contained"
                            color="secondary"
                        >
                            Нулиране
                        </Button>
                    </Grid>
                }
            </Grid>
        </form>
    );
};

export default MyForm;

import { Column } from "../../components/shared/TableComponent";
import { DbMaterial } from "../../models/Storage/Material";
import { DbRawMaterial } from "../../models/Storage/RawMaterial";
import { DbProduction } from "../../models/Storage/Production";
import { DbInstrument } from "../../models/Storage/Instrument";
import { DbMachine } from "../../models/Storage/Machine";
import { ExportableColumn } from "../../components/ComponentToPrint/TableComponent";
import moment from "moment";
import { momentFormat } from "./FormUtils";
import Manufacture from "../../models/manufacture/Manufacture";
// import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import DBDataBases, { MapDataBasesTypes } from "../../models/DataBases/DataBases";
import DBContragent from "../../models/Contragents/Contragents";
import DBExhibitions from "../../models/Exhibitions/Exhibitions";
import { getStateString } from "../consts/Enums";

export type WorkingTypes = DbMaterial | DbRawMaterial | DbProduction | DbInstrument | DbMachine;

export function getColumnsForExport(columns: Array<Column>): Array<ExportableColumn> {
	return columns.filter((c) => c.accessor).map((col) => {
		return {
			name: col.header,
			value: col.accessor
		}
	}) as Array<ExportableColumn>;
}

export function getDataForExport(data: WorkingTypes[]) {
	return data.map((item) => item.dataToSave());
}

export function getManufactureColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			value: "name",
			name: "Наименование",
		},
		{
			value: "quantity",
			name: "Количество",
		},
		{
			value: "startDate",
			name: "Начална дата",
		},
		{
			value: "endDate",
			name: "Крайна дата",
		},
	];
}

export function getManufactureDataForExport(data: Array<Manufacture>) {
	return data.map((item) => {
		return {
			name: item.name,
			quantity: item.quantity,
			startDate: moment(item.startDate).format(momentFormat),
			endDate: item.endDate ? moment(item.endDate).format(momentFormat) : "",
		}
	});
}

export function getDataBasesColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name"
		},
		{
			name: "Категория",
			value: "type"
		},
		{
			name: "ЕИК",
			value: "eik"
		},
		{
			name: "Регистрация по ДДС",
			value: "dds"
		},
		{
			name: "Услуги",
			value: "services"
		},
		{
			name: "Държава",
			value: "country"
		},
		{
			name: "Адрес",
			value: "address"
		},
		{
			name: "Лицa за контакт",
			value: "dynamicRows"
		},
		{
			name: "Сайт",
			value: "site"
		},
		{
			name: "Други",
			value: "others"
		},
	]
}
export function getBusinessColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име",
			value: "name"
		},
		{
			name: "Категория",
			value: "category"
		},
		{
			name: "Запитвания",
			value: "queries"
		},
		{
			name: "Оферти",
			value: "offers"
		},
		{
			name: "Договори",
			value: "contracts"
		},
		{
			name: "Рекламации",
			value: "returns"
		},
		{
			name: "Референции",
			value: "references"
		},
		{
			name: "Забележки",
			value: "notes"
		},

	]
}

export function getPlaningColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name"
		},
		{
			name: "Категория",
			value: "type"
		},
		{
			name: "Раздел",
			value: "section"
		},
		{
			name: "Описание",
			value: "description"
		},
		{
			name: "Целева група",
			value: "targetGroup"
		},
		{
			name: "Забележки",
			value: "notes"
		},
	]
}

export function getExhibitionsColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име на изложението",
			value: "name"
		},
		{
			name: "Тема",
			value: "topic"
		},
		{
			name: "Място",
			value: "place"
		},
		{
			name: "Посетител",
			value: "visitor"
		},
		{
			name: "Дата",
			value: "date"
		},
		{
			name: "Бележки",
			value: "notes"
		},
	]
}

export function getContragentsColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име",
			value: "name"
		},
		{
			name: "Категория",
			value: "category"
		},
		{
			name: "Изпратена фирмена презентация",
			value: "sentPresentation"
		},
		{
			name: "Получена фирмена презентация",
			value: "receivedPresentation"
		},
		{
			name: "Проведена среща",
			value: "meeting"
		},
		{
			name: "Наименования",
			value: "dynamicRows"
		},
	]
}
export function getServicingColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име Контрагент",
			value: "nameContragent"
		},
		{
			name: "Категория",
			value: "category"
		},
		{
			name: "Наименование на поръчката",
			value: "orderName"
		},
		{
			name: "Гаранционен срок",
			value: "waranty"
		},
		{
			name: "Рекламации",
			value: "dynamicRows"
		},
		{
			name: "Референция",
			value: "wantedReference"
		},
	]
}

export function getContragentsDataForExport(data: Array<DBContragent>) {
	return data.map(item => {
		return {
			...item.dataToSave(),
		}
	})
}

export function getExhibitionsDataForExport(data: Array<DBExhibitions>) {
	return data.map(item => {
		return {
			...item.dataToSave(),
		}
	})
}

export function getDataBasesDataForExport(data: Array<DBDataBases>) {
	const g = data.map(item => {
		return {
			...item.dataToSave(),
			type: MapDataBasesTypes[item.type],
		}
	});
	return g;
}

export function getProcessColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name",
		},
		{
			name: "Статус",
			value: "state",
		},
		{
			name: "Дата на започване",
			value: "startDate",
		},
		{
			name: "Дата на приключване",
			value: "endDate",
		},
	];
}


export function getEmployeeColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Имена",
			value: "names"
		},
		{
			name: "Дата на започване",
			value: "start_date",
		},
		{
			name: "Позиция",
			value: "position",
		},
		{
			name: "Умения",
			value: "skills"
		},
		{
			name: "Други",
			value: "other",
		},
		{
			name: "Общо работни часове",
			value: "total_working_hours",
		}
	];
}

export function getColumns(columns: Array<ExportableColumn>): Array<any> {
	return columns.map((col) => {
		const label = col.name || '';
		const key = col.value || '';

		return {
			label,
			key,
		};
	});
}

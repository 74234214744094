import React, { useEffect, useState } from "react";
import {
    Box,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import CardComponent from "../shared/CardComponent";
import FormComponent, { FormData } from "../shared/test";
import { useHistory, useParams } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import { find, findAndUpdate, store } from "../../services/service";
import { dataBasesTypeOptions } from "../../models/DataBases/DataBases";


export default function DataBases() {
    let { id }: any = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({ dynamicRows: [] as any });
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            find("databases", id).then((val) => {
                if (!!!val?.dynamicRows) {
                    val.dynamicRows = [];
                }
                setFormData(val);
            }).finally(() => setIsLoading(false));
        }
    }, [id]);
    console.log(formData);
    return (
        <Box>
            <CardComponent title={`Добавяне`} isLoading={isLoading}>
                <FormComponent
                    staticFields={[
                        {
                            name: 'name', label: 'Име', type: 'text', required: true,
                            validation: (v) => {
                                if (!v) {
                                    return "Полето е задължително!"
                                }
                            }
                        },
                        { name: 'category', label: 'Категория', type: 'autocomplete', required: true, options: dataBasesTypeOptions.map((el) => el.value) },
                        { name: 'eik', label: 'ЕИК', type: "text", required: false },
                        { name: 'dds', label: 'Регистрация по ДДС', type: "checkbox", required: false },
                        { name: 'services', label: 'Услуги', type: "text", required: false },
                        { name: 'country', label: 'Държава', type: "text", required: false },
                        { name: 'address', label: 'Адрес', type: "text", required: false },
                        { name: 'site', label: 'Сайт', type: "text", required: false },
                        { name: 'others', label: 'Други', type: "text", required: false },
                    ]}
                    dynamicFields={[
                        { name: 'name', label: 'име', size: 3, type: 'text', required: true, },
                        { name: 'position', label: 'длъжност', size: 3, type: 'text', required: false },
                        { name: 'phone', label: 'телефон', size: 2, type: "text", required: false },
                        { name: 'email', label: 'имейл', size: 2, type: "text", required: false },
                    ]}
                    dynamicPlural="Лицa за контакт"
                    dynamicSingle="Лице за контакт"
                    onSubmit={async (data) => {
                        try {
                            if (id) {
                                await findAndUpdate("databases", id, data);
                                toast.success("Успешно редактиране");
                                push(RoutesUrl.planing.list);
                                return;
                            }
                            await store("databases", data);
                            toast.success("Успешно добавяне");
                            push(RoutesUrl.planing.list);
                            return true;
                        } catch (e) {
                            toast.error("Нещо се обърка");
                            console.error(e);
                            return false;
                        }
                    }}
                    editMode={id ? true : false}
                    formData={formData}
                    setFormData={setFormData}
                />
            </CardComponent>
        </Box>
    );
}

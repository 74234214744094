import React, { useEffect, useState } from "react";
import {
    Box,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import CardComponent from "../shared/CardComponent";
import FormComponent, { FormData } from "../shared/test";
import { useHistory, useParams } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import { find, findAndUpdate, getData, store } from "../../services/service";


export default function Servicing() {
    let { id }: any = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contragents, setContragents] = useState<FormData[]>([]);
    const [formData, setFormData] = useState<FormData>({ dynamicRows: [] as any });

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            find("servicing", id).then((val) => {
                if (!!!val?.dynamicRows) {
                    val.dynamicRows = [];
                }
                setFormData(val);
            }).finally(() => setIsLoading(false));
        }

        getData("contragents").then(((v) => {
            getData("servicing").then(((servicing) => {
                const filteredContragents = v.filter(c => {
                    const existingOrderNames = servicing?.filter(s => s?.nameContragent === c.name)?.map(s => s?.orderName);
                    c.dynamicRows = c?.dynamicRows?.filter((r: any) => !!existingOrderNames?.length ? !existingOrderNames?.includes(r?.orderName) : true)
                    if (c?.dynamicRows?.find((r: any) => r.orderDone === true && !!r?.orderName?.length)) {
                        return true;
                    }
                    return false;
                });
                if (filteredContragents.length > 0) {
                    setContragents(filteredContragents);
                }
            }));
        }));

    }, [id]);
    return (
        <Box>
            <CardComponent title={`Добавяне`} isLoading={isLoading}>
                <FormComponent
                    staticFields={[
                        {
                            name: 'nameContragent',
                            label: 'Име Контрагент',
                            type: 'autocomplete',
                            required: true,
                            options: contragents.map(c => c.name as string),
                            onChange: () => {
                                if (!!!id) {
                                    setFormData(((v) => {
                                        v.orderName = "";
                                        v.category = contragents?.find(d => d.name === v.nameContragent)?.category;
                                        v.orderName = contragents?.find(c => c.name === v.nameContragent)?.dynamicRows?.filter(r => r.orderDone === true).map(r => r.orderName as string)[0]
                                        return v;
                                    }));
                                }
                            }
                        },
                        { name: 'category', label: 'Категория', type: 'text', disabled: true, required: true },
                        {
                            name: 'orderName',
                            label: 'Наименование на поръчката',
                            type: 'text',
                            required: true,
                            disabled: true
                        },
                        {
                            name: 'waranty',
                            label: 'Гаранционен срок',
                            type: 'checkbox',
                            required: false,
                            size: 4
                        },
                        {
                            name: 'warantyMonths',
                            label: 'Гаранционен срок в месеци',
                            type: 'number',
                            required: !!formData?.waranty,
                            disabled: !formData?.waranty,
                            size: 4,
                        },
                        {
                            name: 'warantyDate',
                            label: 'Гаранционен срок до',
                            type: 'date',
                            required: !!formData?.waranty,
                            disabled: !formData?.waranty,
                            size: 4,
                        },
                        {
                            name: 'wantedReference',
                            label: 'Исканa референция',
                            type: 'checkbox',
                            required: false,
                            size: 4,
                        },
                        {
                            name: 'communication',
                            label: 'Комуникация',
                            type: 'autocomplete',
                            options: ["e-mail", "телефон", "посещение"],
                            required: !!formData?.wantedReference,
                            disabled: !formData?.wantedReference,
                            size: 4,
                        },
                        {
                            name: 'receivedReference',
                            label: 'Получена референция',
                            type: 'checkbox',
                            required: !!formData?.wantedReference,
                            disabled: !formData?.wantedReference,
                            size: 4,
                        },
                    ]}
                    dynamicFields={[
                        { name: 'description', label: 'Описание', size: 2, type: 'text', required: true, },
                        { name: 'descriptionDate', label: 'Описание от дата', size: 2, type: 'date', required: true },
                        { name: 'response', label: 'Отговор', size: 3, type: 'text', required: true },
                        { name: 'responseDate', label: 'Отговор от дата', size: 2, type: 'date', required: true },
                        {
                            name: 'communication',
                            label: 'Комуникация',
                            type: 'autocomplete',
                            options: ["e-mail", "телефон", "посещение"],
                            required: !!formData?.wantedReference,
                            disabled: !formData?.wantedReference,
                            size: 1,
                        },
                    ]}
                    dynamicPlural="Рекламации"
                    dynamicSingle="Рекламация"
                    onSubmit={async (data) => {
                        try {
                            if (id) {
                                await findAndUpdate("servicing", id, data);
                                toast.success("Успешно редактиране");
                                push(RoutesUrl.servicing.list);
                                return;
                            }
                            await store("servicing", data);
                            toast.success("Успешно добавяне");
                            push(RoutesUrl.servicing.list);
                            return true;
                        } catch (e) {
                            toast.error("Нещо се обърка");
                            console.error(e);
                            return false;
                        }
                    }}
                    editMode={id ? true : false}
                    formData={formData}
                    setFormData={setFormData}
                />
            </CardComponent>
        </Box>
    );
}

import { States } from "./Enums";

export const RoutesParams = {
	id: 'id',
	state: 'state',
};

const getParam = (param: string) => `:${param}`;

export const RoutesUrl = {
	dataBases: {
		list: "/dashboard/dataBases/spisak",
		create: "/dashboard/dataBases/novo",
		edit: (id?: string) => `/dashboard/dataBases/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	planing: {
		list: "/dashboard/planirane/spisak",
		create: "/dashboard/planirane/novo",
		edit: (id?: string) => `/dashboard/planirane/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	staff: {
		list: "/dashboard/personal/spisak",
		create: "/dashboard/personal/suzdavane",
		edit: (id?: string) => `/dashboard/personal/edit/${id || getParam(RoutesParams.id)}`,
	},
	process: {
		list: "/dashboard/procesi/spisak",
		create: "/dashboard/procesi/suzdavane",
		edit: (id?: string) => `/dashboard/procesi/redakciya/${id || getParam(RoutesParams.id)}`,
	},
	report: {
		planned: "/dashboard/reports/planirani-deinosti",
		processes: (type?: States) => `/dashboard/reports/procesi/${type || getParam(RoutesParams.state)}`,
		staff: "/dashboard/reports/sluzhiteli",
	},
	businessAnalytics: {
		list: "/dashboard/dataBases/spisak",
		create: "/dashboard/dataBases/novo",
		edit: (id?: string) => `/dashboard/dataBases/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	contragents: {
		list: "/dashboard/contrangents/sushestvyvashti",
		create: "/dashboard/contrangents/novo",
		edit: (id?: string) => `/dashboard/contrangents/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	exhibitions: {
		list: "/dashboard/izlojeniq/spisak",
		create: "/dashboard/izlojeniq/novo",
		edit: (id?: string) => `/dashboard/izlojeniq/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	servicing: {
		list: "/dashboard/obslujvane/spisak",
		create: "/dashboard/obslujvane/novo",
		edit: (id?: string) => `/dashboard/obslujvane/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	businessAnalysis: {
		list: "/dashboard/businessanalysis/spisak",
		create: "/dashboard/businessanalysis/novo",
		edit: (id?: string) => `/dashboard/businessanalysis/redaktirane/${id || getParam(RoutesParams.id)}`,
	}
};

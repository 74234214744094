import React, { useEffect, useState } from "react";
import {
    Box,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import CardComponent from "../shared/CardComponent";
import FormComponent, { FormData } from "../shared/test";
import { useHistory, useParams } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import { find, findAndUpdate, store } from "../../services/service";


export default function DataBases() {
    let { id }: any = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({ dynamicRows: [] as any });
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            find("exhibitions", id).then((val) => {
                if (!!!val?.dynamicRows) {
                    val.dynamicRows = [];
                }
                setFormData(val);
            }).finally(() => setIsLoading(false));
        }
    }, [id])
    return (
        <Box>
            <CardComponent title={`Добавяне`} isLoading={isLoading}>
                <FormComponent
                    staticFields={[
                        { name: 'name', label: 'Име на изложението', type: 'text', required: true },
                        { name: 'topic', label: 'Тема', type: 'text', required: true },
                        { name: 'place', label: 'Място', type: 'text', required: true },
                        { name: 'visitor', label: 'Посетител', type: 'text', required: true },
                        { name: 'date', label: 'Дата', type: 'date', required: true },
                        { name: 'notes', label: 'Бележки', type: 'text', required: false },
                    ]}
                    onSubmit={async (data) => {
                        try {
                            if (id) {
                                await findAndUpdate("exhibitions", id, data);
                                toast.success("Успешно редактиране");
                                push(RoutesUrl.exhibitions.list);
                                return;
                            }
                            await store("exhibitions", data);
                            toast.success("Успешно добавяне");
                            push(RoutesUrl.exhibitions.list);
                            return true;
                        } catch (e) {
                            toast.error("Нещо се обърка");
                            console.error(e);
                            return false;
                        }
                    }}
                    editMode={id ? true : false}
                    formData={formData}
                    setFormData={setFormData}
                />
            </CardComponent>
        </Box>
    );
}

import React, { LegacyRef, useEffect, useRef, useState } from "react";
import TableComponent, { Column } from "../shared/TableComponent";
import CardComponent from "../shared/CardComponent";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import DBDataBases, { MapDataBasesTypes, dataBasesTypeOptions, DataBasesTypes } from "../../models/DataBases/DataBases";
import { getData, setDbNote } from "../../services/service";
import EyeIcon from "@material-ui/icons/Visibility";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import { getColumns, getBusinessColumnsForExport } from "../../common/Utils/ActionUtils";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import Chart from "./Chart";
import { useReactToPrint } from "react-to-print";
const formatData = (data: any[], contragents: any[], services: any[]) => {
	return data.map(el => {
		const contragent = contragents.find(c => c.name === el.name);
		const orderNames = services?.filter(s => s?.nameContragent === el?.name);
		el.queries = contragent?.dynamicRows?.filter((r: any) => r?.receivedOffer === true)?.length || 0;
		el.offers = contragent?.dynamicRows?.filter((r: any) => r?.sentOffer === true)?.length || 0;
		el.contracts = contragent?.dynamicRows?.filter((r: any) => r?.contract === true)?.length || 0;
		el.references = orderNames?.filter((o: any) => o?.wantedReference === true)?.length || 0;
		const returns: any[] = [];
		orderNames?.forEach(o => {
			o?.dynamicRows?.forEach((r: any) => {
				returns.push(r);
			});
		})
		el.returns = returns?.length || 0;
		Object.keys(el).forEach(key => {
			if (key === "dynamicRows") {
				let i = 0;
				let finalString = "";
				for (const person of el[key]) {
					if (person) {
						let str = "";
						if (person?.name?.length > 0) {
							str += "Име: " + person.name;
						}
						if (person?.position?.length > 0) {
							str += ", Длъжност: " + person.position;
						}
						if (person?.phone?.length > 0) {
							str += ", Тел.: " + person.phone;
						}
						if (person?.email?.length > 0) {
							str += ", Имейл: " + person.email + "";
						}
						if (str.length > 0) {
							i++;
							finalString += i + "." + str + '<br/>';
						}
					}
				}
				el[key] = finalString;
			}
			if (typeof el[key] === 'boolean') {
				if (el[key]) {
					el[key] = "Да"
				} else {
					el[key] = "Не"
				}
			}
		});
		return el;
	})
}
const getCsvData = (data: any[]) => {
	return data.map(el => {
		Object.keys(el).forEach(key => {
			if (key === "dynamicRows") {
				el[key] = el[key].split("<br/>").join('\n');
			}
		});
		return el;
	})
}
function List() {
	const [data, setData] = useState<Array<DBDataBases>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = useState<DataBasesTypes | string>("");
	const [selectedName, setSelectedName] = useState<string>("");
	const [currentSearch, setCurrentSearch] = useState<DataBasesTypes | string>("");
	const [noteId, setNoteId] = useState<string | undefined>(undefined);
	const [note, setNote] = useState<string | undefined>(undefined);
	const [viewId, setViewId] = useState<string | undefined>(undefined);

	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			getData("databases"),
			getData("contragents"),
			getData("servicing")
		]).then(([dbDatabases, dbContragents, dbServicing]) => {
			setData(formatData(dbDatabases, dbContragents, dbServicing));
		}).finally(() => setIsLoading(false));
	}, [])

	const columns: Array<Column> = [
		{
			accessor: "name",
			cell: (val) => {
				return (
					<Typography variant="body1">{val}</Typography>
				)
			},
			header: "Име"
		},
		{
			accessor: "category",
			cell: (val: DataBasesTypes) => {
				return (
					<Typography variant="body1">{MapDataBasesTypes[val]}</Typography>
				)
			},
			header: "Категория"
		},
		{
			accessor: "queries",
			cell: (val) => {
				return (
					<Typography variant="body1" title={val}>{val}</Typography>
				)
			},
			header: "Запитвания"
		},
		{
			accessor: "offers",
			cell: (val: string | undefined) => {
				return (
					<Typography variant="body1" title={val}>{val}</Typography>
				);
			},
			header: "Оферти"
		},
		{
			accessor: "contracts",
			cell: (val: string) => {
				return (
					<Typography variant="body1" title={val}>{val}</Typography>
				)
			},
			header: "Договори"
		},
		{
			accessor: "returns",
			cell: (val: string) => {
				return (
					<Typography variant="body1" title={val}>{val}</Typography>
				)
			},
			header: "Рекламации"
		},
		{
			accessor: "references",
			cell: (val: string) => {
				return (
					<Typography variant="body1" title={val}>{val}</Typography>
				)
			},
			header: "Референции"
		},
		{

			cell: (val: any) => {
				return (
					<TextField
						key={val.id}
						type="textarea"
						multiline
						minRows={2}
						variant="filled"
						label="Забележки"
						fullWidth
						defaultValue={val?.notes}
						onChange={(e) => {
							setData((v: any) => {
								v.find((el: any) => el.id === noteId).notes = e.target.value;
								return v;
							});
							if (noteId === val.id) {
								setNote(e.target.value);
							}
						}}
						onFocus={() => setNoteId(val.id)}
					/>
				)
			},
			header: "Забележки"
		},

		{
			cell: (v) => {
				return (
					<IconButton onClick={() => setViewId(v.id)}>
						<EyeIcon color="primary"></EyeIcon>
					</IconButton>
				)
			},
			header: "Графично Представяне"
		},
	];

	useEffect(() => {
		const timer = setTimeout(() => {
			if (noteId) {
				setDbNote(noteId, note || "");
			}
		}, 500)
		return () => clearTimeout(timer)
	}, [data, note, noteId])

	let componentRef = useRef<TableComponentToPrint | null>(null);
	let chartRef = useRef<HTMLDivElement | null>(null);

	const csvColumns = getBusinessColumnsForExport();

	const renderHeaderActions = () => {
		if (!data.length) return;
		const csvData = getCsvData(data);
		const csvFileName = `Report_${+new Date()}.csv`;
		return (
			<CardHeaderActions
				csvHeaders={getColumns(csvColumns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	const filterData = (data: any[]) => {
		return data.filter((el) => {
			if (!el?.country) {
				el.country = "";
			}
			let searchConcat = (el?.name || "") + " " + (el?.country || "") + " " + (el?.dynamicRows || "") + " " + (el?.other || "");
			return (selectedName.length > 0 ? el.name === selectedName : true) && el.category.includes(selectedCategory || "") && searchConcat.toLowerCase().includes(currentSearch.toLowerCase() || "");
		})
	}
	const viewItem = data?.find(d => d.id === viewId);
	const chartData = [
		{
			name: "Запитвания",
			uv: (viewItem as any)?.queries || 0,
			fill: '#2ecc71',
		},
		{
			name: "Оферти",
			uv: (viewItem as any)?.offers || 0,
			fill: '#3498db',
		},
		{
			name: "Договори",
			uv: (viewItem as any)?.contracts || 0,
			fill: '#f1c40f',
		},
		{
			name: "Рекламации",
			uv: (viewItem as any)?.returns || 0,
			fill: '#f39c12',
		},
		{
			name: "Референции",
			uv: (viewItem as any)?.references || 0,
			fill: '#c0392b',
		},
	];

	const handlePrint = useReactToPrint({
		content: () => chartRef.current,
	});

	return (
		<>
			<Dialog open={!!viewId}>
				<div ref={chartRef}>
					<DialogTitle>{viewItem?.name} </DialogTitle>
					<DialogContent>
						<Box width="500px" maxWidth="100%" >
							<Chart data={chartData}></Chart>
						</Box>
					</DialogContent>
				</div>
				<DialogActions>
					<Button color="primary" onClick={() => {
						handlePrint && handlePrint();
					}}>Принтирай</Button>
					<Button color="inherit" onClick={() => setViewId(undefined)} autoFocus>
						Затвори
					</Button>
				</DialogActions>
			</Dialog>
			<CardComponent title={`Бизнес Анализ`} isLoading={isLoading} headerActions={renderHeaderActions()}>
				<Box>
					<Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
						<Grid item xs={3}>
							<FormControl style={{ width: "100%" }}>
								<InputLabel>Категория</InputLabel>
								<Select
									fullWidth
									onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
										setSelectedCategory(e.target.value as string || "");
									}}
									label={"Категория"}
								>
									<MenuItem value={""} key={-1}>Моля, изберете</MenuItem>
									{
										dataBasesTypeOptions.map((item) => (
											<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<FormControl style={{ width: "100%" }}>
								<InputLabel>Име</InputLabel>
								<Select
									fullWidth
									onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
										setSelectedName(e.target.value as string || "");
									}}
									label={"Име"}
								>
									<MenuItem value={""} key={-1}>Моля, изберете</MenuItem>
									{
										data.filter((d: any) => selectedCategory.length > 0 ? d.category as string === selectedCategory : true).map((d) => (
											<MenuItem value={d.name} key={d.name}>{d.name}</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<FormControl style={{ width: "100%" }}>
								<TextField
									fullWidth
									onChange={(e) => {
										setCurrentSearch(e.target.value as string);
									}}
									label={"Търсене..."}
								/>
							</FormControl>
						</Grid>
					</Grid>
					{
						data.length > 0 && (
							<div style={{ display: "none" }}>
								<TableComponentToPrint
									ref={componentRef}
									data={data}
									headers={csvColumns}
									title={"Бизнес Анализ"}
								/>
							</div>
						)
					}
					<Box marginBottom="15px" marginTop="15px">
						<Divider />
					</Box>
					<TableComponent data={filterData(data)} columns={columns} />
				</Box>
			</CardComponent>
		</>
	)
}

export default List;
